export const spacing = {
  S0: 0,
  S2: 2,
  S4: 4,
  S8: 8,
  S12: 12,
  S16: 16,
  S24: 24,
  S32: 32,
  S48: 48,
} as const;

export type Spacing = keyof typeof spacing;
export type AutoSpacing = Spacing | "auto";

export function getSpacing(sp: AutoSpacing | undefined) {
  if (!sp) {
    return undefined;
  }
  if (sp === "auto") {
    return "auto";
  }
  return spacing[sp];
}

export const radius = {
  small: 2,
  default: 4,
  round: 10,
  big: 16,
  max: 10000,
} as const;

export const fontSize = {
  main: 60,
  big: 30,
  title: 24,
  default: 18,
  smaller: 15,
  small: 12,
  tiny: 8,
};

export const rawColor = {
  one: "#1C1C1C",
  two: "#111111",
  three: "#FFFED5",
  four: "#FEE402",
  five: "#FDCA00",

  white: "white",
  grey: "grey",
};

export const color = {
  background: rawColor.one,
  backgroundDark: rawColor.two,

  textYellow: rawColor.four,
  textYellowFaded: rawColor.three,
  textYellowMat: rawColor.five,

  text: rawColor.white,
  textGrey: rawColor.grey,

  twitch: "#9245FF",

  warning: "orange",
  error: "red",
  success: "green",
};

export function injectCssVariables() {
  const node = document.createElement("style");
  node.innerHTML = `:root {
  ${Object.entries(rawColor)
    .map(([key, value]) => `--${key}: ${value};`)
    .join("\n")}
        ${Object.entries(color)
          .map(([key, value]) => `--${key}: ${value};`)
          .join("\n")}
        }`;
  document.head.appendChild(node);
}

export const shadowTemplate = {
  big: "0px 0px 32px 23px COLOR",
  small: "0px 0px 10px 0px COLOR",
};

export function templateShadow(
  key: keyof typeof shadowTemplate,
  color: keyof typeof rawColor | (string & {}),
) {
  return shadowTemplate[key].replace(
    "COLOR",
    color in rawColor ? (rawColor as any)[color] : color,
  );
}

export const shadow = {
  big: templateShadow("big", "rgba(0,0,0,0.18)"),
};

export type Color = keyof typeof color;

export function getColor(c: Color | undefined) {
  if (!c) {
    return undefined;
  }
  return color[c];
}

function shadeColor(c: string, percent: number) {
  let R = Number.parseInt(c.substring(1, 3), 16);
  let G = Number.parseInt(c.substring(3, 5), 16);
  let B = Number.parseInt(c.substring(5, 7), 16);

  R = Math.floor(R * (1 + percent));
  G = Math.floor(G * (1 + percent));
  B = Math.floor(B * (1 + percent));

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  const RR =
    R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
}

export function opacifyRaw(c: string, t: number) {
  return `${c}${Math.floor(t * 256)
    .toString(16)
    .padStart(2, "0")}`;
}

export function opacify(c: keyof typeof color, t: number) {
  return opacifyRaw(color[c], t);
}

export const debugBorder = (c = "red") => ({
  borderWidth: 2,
  borderColor: c,
  borderStyle: "solid",
});

export const durations = {
  default: 175,
};
