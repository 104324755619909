import { Fade, Modal as MuiModal } from "@mui/material";
import { ReactNode } from "react";
import { Text } from "../text/text";
import s from "./index.module.css";

interface ModalProps {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  title: string;
}

export function Modal({ children, open, onClose, title }: ModalProps) {
  return (
    <MuiModal open={open} onClose={onClose} closeAfterTransition>
      <Fade in={open}>
        <div className={s.wrapper}>
          <div className={s.content}>
            <Text weight="bold" size="title" className={s.title}>
              {title}
            </Text>
            {children}
          </div>
        </div>
      </Fade>
    </MuiModal>
  );
}
