import { ArrowDropDown } from "@mui/icons-material";
import { Popover } from "@mui/material";
import { useRef } from "react";
import { useBooleanState } from "../../services/hooks";
import { Box } from "../box/box";
import { Text } from "../text/text";
import s from "./index.module.css";

interface SelectorProps {
  title: string;
  options: { title: string; subtitle: string; onClick: () => void }[];
}

export function Selector({ options, title }: SelectorProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const [isOpen, open, close] = useBooleanState();

  return (
    <>
      <button ref={ref} onClick={open} type="button" className={s.button}>
        <Text color="textGrey">{title}</Text>
        <ArrowDropDown />
      </button>
      <Popover
        open={isOpen}
        onClose={close}
        anchorEl={ref.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        sx={{ border: "none" }}
        slotProps={{
          paper: { sx: { background: "transparent" } },
        }}
      >
        <Box bg="background" w={ref.current?.clientWidth}>
          {[...options].reverse().map((option) => (
            <button
              key={option.title}
              className={s.option}
              type="button"
              onClick={() => {
                option.onClick();
                close();
              }}
            >
              <div>
                <Text color="text">{option.title}</Text>
              </div>
              <div>
                <Text color="textGrey" size="smaller">
                  {option.subtitle}
                </Text>
              </div>
            </button>
          ))}
        </Box>
      </Popover>
    </>
  );
}
