export interface TrackmaniaMap {
  id: string;
  path: string;
  by: string;
  shadows: number;
  times: {
    bronze: number;
    silver: number;
    gold: number;
    author: number;
  };
}

export interface MappingEventMapInfo {
  map: TrackmaniaMap;
  times: Array<{
    mapId: string;
    times: {
      ubisoftId: string;
      name: string;
      time: number;
    };
  }>;
}

export interface MappingEventParticipation {
  id: string;
  name: string;
  profilePictureUrl: string;
  tier: number;
  mapper: boolean;
  mapId: string | undefined;
}

export const MappingEventSteps = [
  "initial",
  "registration_open",
  "mapping_started",
  "competition_started",
  "finished",
] as const;
export type MappingEventStep = (typeof MappingEventSteps)[number];

export const MappingEventStepString: Record<MappingEventStep, string> = {
  initial: "Registrations not opened",
  registration_open: "Registrations opened",
  mapping_started: "Mapping started",
  competition_started: "Competition started",
  finished: "Event finished",
};

export const MappingEventNextStep: Record<MappingEventStep, string> = {
  initial: "Open registrations",
  registration_open: "Choose mappers",
  mapping_started: "Start competition",
  competition_started: "End competition",
  finished: "",
};

export interface MappingEvent {
  id: string;
  index: number;
  createdAt: Date;
  theme: string;
  participations: MappingEventParticipation[];
  step: MappingEventStep;
}

export interface ListMappingEvent {
  id: string;
  mappingEvents: Array<{
    id: string;
    createdAt: Date;
    theme: string;
    step: MappingEventStep;
  }>;
}

export interface TimeScoreboardMappingEvent {
  id: string;
  names: Record<string, string>;
  perMap: Array<{
    mapId: string;
    mapName: string;
    times: Array<{
      ubisoftId: string;
      time: number;
    }>;
    medals: {
      bronze: number;
      silver: number;
      gold: number;
      author: number;
    };
  }>;
  total: Array<{
    ubisoftId: string;
    time: number;
  }>;
}

export interface VoteScoreboardMappingEvent {
  id: string;
  votes: Array<{
    id: string;
    vote: number;
    name: string;
    mapperName: string;
    chatVote: number;
  }>;
}
