import { DocumentData } from "firebase/firestore";

export function decodeFirebaseTimestamps(obj: DocumentData): DocumentData {
  if (obj instanceof Date) {
    return obj;
  }
  if (obj?.toDate) {
    return obj.toDate();
  }
  if (Array.isArray(obj)) {
    return obj.map(decodeFirebaseTimestamps);
  }
  if (obj instanceof Object) {
    return Object.entries(obj).reduce(
      (map, [key, value]) => {
        map[key] = decodeFirebaseTimestamps(value);
        return map;
      },
      {} as { [key: string]: any },
    );
  }
  return obj;
}
