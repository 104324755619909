import { Button } from "../../../../components/button/button";
import { MappingEvent } from "../../../../services/modules/mappingEvent/mappingEvent";
import { useMe } from "../../../../services/modules/user/useMe";
import { Participations } from "../../components/participations/participations";
import { useUploadMapPopup } from "./uploadMapPopup/useUploadMapPopup";

interface MappingStartedProps {
  mappingEvent: MappingEvent;
}

export function MappingStarted({ mappingEvent }: MappingStartedProps) {
  const { user } = useMe();
  const { open, element } = useUploadMapPopup({
    mappingEventId: mappingEvent.id,
  });

  const isMapping = user
    ? mappingEvent.participations.some(
        (participation) => participation.id === user.id && participation.mapper,
      )
    : false;

  return (
    <>
      <Participations
        participations={mappingEvent.participations}
        rightMappersButton={
          isMapping && (
            <div>
              <Button onClick={open}>Upload a map</Button>
            </div>
          )
        }
        shouldWarnNotUploaded
        forceParticipantDisplay={false}
      />
      {element}
    </>
  );
}
