import { Box } from "../../../../components/box/box";
import { useVoteScoreboardMappingEvent } from "../../../../services/modules/mappingEvent/useMappingEvent";
import { VoteScoreboardLine } from "./voteScoreboardLine/voteScoreboardLine";

interface VoteScoreboardProps {
  mappingEventId: string;
}

export function VoteScoreboard({ mappingEventId }: VoteScoreboardProps) {
  const scoreboard = useVoteScoreboardMappingEvent(mappingEventId);

  return (
    <Box gap="S8">
      {scoreboard?.votes.map((score, index) => (
        <VoteScoreboardLine
          key={score.id}
          mappingEventId={mappingEventId}
          mapId={score.id}
          rank={index + 1}
          vote={score.vote}
          name={score.name}
          mapperName={score.mapperName}
          chatVote={score.chatVote}
        />
      ))}
    </Box>
  );
}
