import Axios from "axios";
import { MappingEventStep } from "./modules/mappingEvent/mappingEvent";

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const Endpoint = Axios.create({
  baseURL: API_ENDPOINT,
  withCredentials: true,
});

async function get<T>(url: string) {
  const { data } = await Endpoint.get<T>(url, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
}

async function post(url: string, body: Record<string, any>) {
  const { data } = await Endpoint.post(url, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
}

async function put(url: string, body: Record<string, any>) {
  const { data } = await Endpoint.put(url, body, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  return data;
}

export const API = {
  me: () => get<{ id: string }>("/me"),
  createMappingEvent: (theme: string) =>
    post("/mapping-event", {
      theme,
    }),
  editMappingEvent: (mappingEventId: string, theme: string) =>
    put(`/mapping-event/${mappingEventId}`, { theme }),
  setMappingEventStep: (mappingEventId: string, step: MappingEventStep) =>
    put(`/mapping-event/${mappingEventId}/step`, {
      mappingEventId,
      step,
    }),
  participateToMappingEvent: (mappingEventId: string) =>
    post(`/mapping-event/${mappingEventId}/participate`, {
      mappingEventId,
    }),
  unparticipateFromMappingEvent: (mappingEventId: string) =>
    post(`/mapping-event/${mappingEventId}/unparticipate`, {
      mappingEventId,
    }),
  uploadMappingEventMap: (mappingEventId: string, map: File) => {
    const formdata = new FormData();

    formdata.append("file", map);

    return Endpoint.post(`/mapping-event/${mappingEventId}/map`, formdata);
  },
  downloadMaps: async (mappingEventId: string) => {
    const { data } = await Endpoint.get(
      `/mapping-event/${mappingEventId}/maps`,
      {
        responseType: "blob",
      },
    );
    return data;
  },
  getMapThumbnailUrl: (mappingEventId: string, mapId: string) =>
    `${API_ENDPOINT}/mapping-event/${mappingEventId}/thumbnail/${mapId}`,
};

export function getCodeFromError(error: any) {
  return error.response?.data?.code;
}

export function getStringFromError(error: any) {
  return ErrorStringFromCode[getCodeFromError(error) ?? "UNKNOWN"];
}

export const ErrorStringFromCode: Record<string, string> = {
  UNKNOWN: "Unknown server error",
  MAP_NOT_VALIDATED: "Your map is not validated",
  MAP_NO_SHADOWS: "Your map has no shadows computed",
  MAP_NOT_VALIDATED_NO_SHADOWS:
    "Your map is not validated and has no shadows computed",
  MAP_NOT_STADIUM: "Your map is not a stadium map",
  MAP_NOT_RACE: "Your map is not a race map (maybe it is a royal map?)",
  MAP_NOT_TM2020:
    "Your map was not built with Trackmania 2020 or is not a race map (maybe it is a royal map?)",
};
