import { ReactNode } from "react";
import { Box } from "../../../../components/box/box";
import { Text } from "../../../../components/text/text";
import { MappingEventParticipation } from "../../../../services/modules/mappingEvent/mappingEvent";
import { UserLine } from "../../../../services/modules/user/components/userLine/userLine";
import s from "./index.module.css";

interface ParticipationsProps {
  participations: MappingEventParticipation[];
  className?: string;
  shouldWarnNotUploaded: boolean;
  rightParticpantsButton?: ReactNode;
  rightMappersButton?: ReactNode;
  forceParticipantDisplay: boolean;
}

interface Participant {
  id: string;
  name: string;
  profilePictureUrl: string;
  atTier: number;
  mapper: boolean;
  uploaded: boolean;
}

export function Participations({
  participations,
  className,
  rightMappersButton,
  rightParticpantsButton,
  shouldWarnNotUploaded,
  forceParticipantDisplay,
}: ParticipationsProps) {
  const usersOfParticipations = participations.reduce<{
    mappers: Array<Participant>;
    others: Array<Participant>;
  }>(
    (acc, curr) => {
      if (curr.mapper) {
        acc.mappers.push({
          id: curr.id,
          name: curr.name,
          profilePictureUrl: curr.profilePictureUrl,
          atTier: curr.tier,
          mapper: curr.mapper,
          uploaded: curr.mapId !== undefined,
        });
      } else {
        acc.others.push({
          id: curr.id,
          name: curr.name,
          profilePictureUrl: curr.profilePictureUrl,
          atTier: curr.tier,
          mapper: curr.mapper,
          uploaded: curr.mapId !== undefined,
        });
      }
      return acc;
    },
    { mappers: [], others: [] },
  );

  const hasMappers = usersOfParticipations.mappers.length > 0;
  const hasOthers = usersOfParticipations.others.length > 0;

  return (
    <div className={className}>
      {hasMappers && (
        <>
          <div className={s.participate}>
            <Text size="title" color="text" weight="bold">
              Mappers
            </Text>
            {rightMappersButton}
          </div>
          <div className={s.participants}>
            {usersOfParticipations.mappers.map(
              ({ id, name, profilePictureUrl, atTier, uploaded }) => (
                <UserLine
                  key={id}
                  name={name}
                  profilePictureUrl={profilePictureUrl}
                  atTier={atTier}
                  uploaded={uploaded}
                  shouldWarnNotUploaded={shouldWarnNotUploaded}
                />
              ),
            )}
          </div>
          <Box mb="S16" />
        </>
      )}
      {(hasOthers || forceParticipantDisplay) && (
        <>
          <div className={s.participate}>
            <Text size="title" color="text" weight="bold">
              Participants
            </Text>
            {rightParticpantsButton}
          </div>
          <div className={s.participants}>
            {usersOfParticipations.others.map(
              ({ id, name, profilePictureUrl, atTier, uploaded }) => (
                <UserLine
                  key={id}
                  name={name}
                  profilePictureUrl={profilePictureUrl}
                  atTier={atTier}
                  uploaded={uploaded}
                  shouldWarnNotUploaded={shouldWarnNotUploaded}
                />
              ),
            )}
          </div>
        </>
      )}
    </div>
  );
}
