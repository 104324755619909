import { Paper, Typography } from "@mui/material";
import { Button } from "../../components/button/button";
import { API_ENDPOINT } from "../../services/api";
import s from "./index.module.css";

export function Login() {
  return (
    <div className={s.root}>
      <div className={s.background} />
      <Paper elevation={4} className={s.content}>
        <Paper elevation={4} className={s.bren}>
          <img src="/bren.png" height={64} width={64} alt="bren" />
        </Paper>
        <Typography
          variant="h5"
          marginTop={1}
          marginBottom={2}
          color="whitesmoke"
        >
          Welcome back
        </Typography>
        <a href={`${API_ENDPOINT}/login`} className={s.link}>
          <Button className={s.button} onClick={() => {}}>
            <img src="/twitch.png" alt="twitch" height={16} width={16} />
            <div>Login with Twitch</div>
          </Button>
        </a>
      </Paper>
    </div>
  );
}
