import { CalendarMonth, Category, People } from "@mui/icons-material";
import { Divider, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "../../components/box/box";
import { Button } from "../../components/button/button";
import { PulsatingDot } from "../../components/pulsatingDot/pulsatingDot";
import { Text } from "../../components/text/text";
import { DateFormatter } from "../../services/date";
import {
  MappingEventStepString,
  MappingEventSteps,
  MappingEvent as MappingEventType,
} from "../../services/modules/mappingEvent/mappingEvent";
import { useMappingEvent } from "../../services/modules/mappingEvent/useMappingEvent";
import { useMe } from "../../services/modules/user/useMe";
import { User } from "../../services/modules/user/user";
import { useAdminPopup } from "./components/adminPopup/useAdminPopup";
import { InfoIcon } from "./components/infoIcon/infoLine";
import { ParticipantMaps } from "./components/participantMaps/participantMaps";
import { TimeScoreboard } from "./components/timeScoreboard/timeScoreboard";
import { VoteScoreboard } from "./components/voteScoreboard/voteScoreboard";
import s from "./index.module.css";
import { CompetitionStarted } from "./steps/competitionStarted/competitionStarted";
import { Finished } from "./steps/finished/finished";
import { Initial } from "./steps/initial/initial";
import { MappingStarted } from "./steps/mappingStarted/mappingStarted";
import { RegistrationsOpened } from "./steps/registrationsOpened/registrationsOpened";

interface MappingEventProps {
  mappingEvent: MappingEventType;
  me: User | undefined;
}

function WrappedMappingEvent({ mappingEvent, me }: MappingEventProps) {
  const { open, element } = useAdminPopup({
    mappingEvent,
  });

  const [tab, setTab] = useState<
    "participants" | "time-scoreboard" | "vote-scoreboard"
  >("participants");
  const stepIndex = MappingEventSteps.indexOf(mappingEvent.step);

  return (
    <>
      <Box pb="S32">
        <Box style={{ position: "relative" }}>
          <ParticipantMaps mappingEvent={mappingEvent} />
          <div style={{ zIndex: 1 }}>
            <Box pt="S32" items="center" content="center">
              <Text color="textYellowMat" size="main" weight="extrabold">
                {mappingEvent.theme}
              </Text>
              <Text color="text" size="title">
                Saturday mapping fest #{mappingEvent.index + 1}
              </Text>
            </Box>
            <Box
              mt="S16"
              mb="S16"
              w={200}
              h={1}
              content="center"
              items="center"
              mh="auto"
            >
              <Divider style={{ backgroundColor: "white", width: "100%" }} />
            </Box>
            <Box items="center" content="center">
              <Box row gap="S32">
                <InfoIcon
                  left={<Category />}
                  right={MappingEventStepString[mappingEvent.step]}
                />
                <InfoIcon
                  left={<People />}
                  right={mappingEvent.participations.length}
                />
                <InfoIcon
                  left={<CalendarMonth />}
                  right={DateFormatter.formatDate(mappingEvent.createdAt)}
                />
              </Box>
              <Box mb="S32">
                {me?.admin && (
                  <Button className={s.admin} onClick={open}>
                    Admin panel
                  </Button>
                )}
              </Box>
            </Box>
          </div>
        </Box>
        <Box w="60%" mh="auto">
          <Tabs value={tab} onChange={(_, value) => setTab(value)}>
            <Tab
              value="participants"
              label={<Text size="smaller">Participants</Text>}
            />
            {stepIndex >= MappingEventSteps.indexOf("competition_started") && (
              <Tab
                value="time-scoreboard"
                label={
                  <Box row items="center" gap="S4">
                    {stepIndex ===
                      MappingEventSteps.indexOf("competition_started") && (
                      <PulsatingDot />
                    )}
                    <Text size="smaller">Scores</Text>
                  </Box>
                }
              />
            )}
            {stepIndex >= MappingEventSteps.indexOf("competition_started") && (
              <Tab
                value="vote-scoreboard"
                label={
                  <Box row items="center" gap="S4">
                    {stepIndex ===
                      MappingEventSteps.indexOf("competition_started") && (
                      <PulsatingDot />
                    )}
                    <Text size="smaller">Votes</Text>
                  </Box>
                }
              />
            )}
          </Tabs>
          <Box mt="S16">
            {tab === "participants" && (
              <>
                {mappingEvent.step === "initial" && <Initial />}
                {mappingEvent.step === "registration_open" && (
                  <RegistrationsOpened mappingEvent={mappingEvent} />
                )}
                {mappingEvent.step === "mapping_started" && (
                  <MappingStarted mappingEvent={mappingEvent} />
                )}
                {mappingEvent.step === "competition_started" && (
                  <CompetitionStarted mappingEvent={mappingEvent} />
                )}
                {mappingEvent.step === "finished" && (
                  <Finished mappingEvent={mappingEvent} />
                )}
              </>
            )}
            {tab === "time-scoreboard" && (
              <TimeScoreboard mappingEventId={mappingEvent.id} />
            )}
            {tab === "vote-scoreboard" && (
              <VoteScoreboard mappingEventId={mappingEvent.id} />
            )}
          </Box>
        </Box>
      </Box>
      {element}
    </>
  );
}

export function MappingEvent() {
  const { id } = useParams();
  const mappingEvent = useMappingEvent(id);
  const { user } = useMe();

  return mappingEvent ? (
    <WrappedMappingEvent me={user} mappingEvent={mappingEvent} />
  ) : null;
}
