import { EmojiEvents } from "@mui/icons-material";
import { Box } from "../../../../../components/box/box";
import { Text } from "../../../../../components/text/text";
import { API } from "../../../../../services/api";
import { color } from "../../../../../services/constants";
import s from "./index.module.css";

interface VoteScoreboardLineProps {
  mappingEventId: string;
  mapId: string;
  rank: number;
  vote: number;
  name: string;
  mapperName: string;
  chatVote: number;
}

export function VoteScoreboardLine({
  mappingEventId,
  mapId,
  rank,
  vote,
  name,
  mapperName,
  chatVote,
}: VoteScoreboardLineProps) {
  return (
    <Box
      p="S8"
      row
      items="center"
      content="space-between"
      bg="backgroundDark"
      r="round"
    >
      <Box row items="center">
        {rank === 1 ? (
          <EmojiEvents style={{ color: color.textYellowMat }} />
        ) : (
          <Text weight="bold">#{rank}</Text>
        )}
        <Box row ml="S16" gap="S8" items="center">
          <img
            src={API.getMapThumbnailUrl(mappingEventId, mapId)}
            height={48}
            width={48}
            alt={`${name} profile`}
            className={s.thumbnail}
          />
          <Box>
            <Box row gap="S8">
              <Text>{name}</Text>
            </Box>
            <Text size="smaller" color="textGrey">
              {mapperName}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box row>
        {chatVote !== -1 && (
          <Text className={s.vote} color="textGrey">
            {Math.floor(chatVote * 100)}%&nbsp;
          </Text>
        )}
        {vote !== -1 && (
          <Text className={s.vote}>{Math.floor(vote * 100)}%</Text>
        )}
      </Box>
    </Box>
  );
}
