import { Tooltip } from "@mui/material";
import { ReactNode } from "react";
import { API } from "../../../../services/api";
import { MappingEvent } from "../../../../services/modules/mappingEvent/mappingEvent";
import s from "./index.module.css";

interface ParticipantMapsProps {
  mappingEvent: MappingEvent;
}

export function ParticipantMaps({ mappingEvent }: ParticipantMapsProps) {
  const [firstLine, secondLine] = mappingEvent.participations.reduce<
    [ReactNode[], ReactNode[]]
  >(
    (acc, participation) => {
      if (!participation.mapId) {
        return acc;
      }

      const total = acc[0].length + acc[1].length;

      acc[total % 2].push(
        <Tooltip key={participation.id} title={`Map of ${participation.name}`}>
          <img
            className={s.thumbnail}
            alt="Map of user"
            src={API.getMapThumbnailUrl(mappingEvent.id, participation.mapId)}
          />
        </Tooltip>,
      );
      return acc;
    },
    [[], []],
  );

  return (
    <div className={s.root}>
      <div className={s.line}>{firstLine}</div>
      <div className={s.line}>{secondLine}</div>
    </div>
  );
}
