import { MappingEvent } from "../../../../services/modules/mappingEvent/mappingEvent";
import { Participations } from "../../components/participations/participations";

interface FinishedProps {
  mappingEvent: MappingEvent;
}

export function Finished({ mappingEvent }: FinishedProps) {
  return (
    <Participations
      participations={mappingEvent.participations}
      shouldWarnNotUploaded={false}
      forceParticipantDisplay={false}
    />
  );
}
