import { useCallback, useEffect, useState } from "react";
import { Box } from "../../../../components/box/box";
import { Button } from "../../../../components/button/button";
import { Input } from "../../../../components/input/input";
import { Modal } from "../../../../components/modal/modal";
import { API } from "../../../../services/api";
import { downloadBlob } from "../../../../services/download";
import { useBooleanState } from "../../../../services/hooks";
import {
  MappingEvent,
  MappingEventNextStep,
  MappingEventSteps,
} from "../../../../services/modules/mappingEvent/mappingEvent";
import s from "./index.module.css";

interface UseAdminPopupProps {
  mappingEvent: MappingEvent;
}

export function useAdminPopup({ mappingEvent }: UseAdminPopupProps) {
  const [isOpen, open, close] = useBooleanState();
  const [theme, setTheme] = useState(mappingEvent.theme);
  const [loading, setLoading] = useState(false);
  const [nextStepLoading, setNextStepLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    setTheme(mappingEvent.theme);
  }, [mappingEvent.theme]);

  const handleRename = useCallback(async () => {
    setLoading(true);
    try {
      await API.editMappingEvent(mappingEvent.id, theme);
    } catch (e) {}
    setLoading(false);
    close();
  }, [close, mappingEvent.id, theme]);

  const mappingEventStepIndex = MappingEventSteps.findIndex(
    (step) => step === mappingEvent.step,
  );

  const handleNextStep = useCallback(async () => {
    setNextStepLoading(true);
    try {
      if (
        mappingEventStepIndex < 0 ||
        mappingEventStepIndex === MappingEventSteps.length - 1
      ) {
        return;
      }
      await API.setMappingEventStep(
        mappingEvent.id,
        MappingEventSteps[mappingEventStepIndex + 1],
      );
    } catch (e) {}
    setNextStepLoading(false);
    close();
  }, [mappingEvent.id, mappingEventStepIndex, close]);

  const handleDownloadMaps = useCallback(async () => {
    setDownloadLoading(true);
    try {
      const blob = await API.downloadMaps(mappingEvent.id);
      downloadBlob(blob, `${mappingEvent.id}.zip`);
    } catch (e) {}
    setDownloadLoading(false);
  }, [mappingEvent.id]);

  const canGoNextStep = mappingEventStepIndex < MappingEventSteps.length - 1;
  const canDownloadMaps =
    mappingEventStepIndex >= MappingEventSteps.indexOf("competition_started");

  return {
    isOpen,
    open,
    close,
    element: (
      <Modal open={isOpen} onClose={close} title="Admin">
        <div className={s.rename}>
          <Input
            placeholder="Change event theme"
            value={theme}
            onChangeText={setTheme}
          />
          <Button onClick={handleRename} loading={loading}>
            Change
          </Button>
        </div>
        <Box row gap="S8" mt="S16" content="center">
          {canGoNextStep && (
            <Button onClick={handleNextStep} loading={nextStepLoading}>
              {MappingEventNextStep[mappingEvent.step]}
            </Button>
          )}
          {canDownloadMaps && (
            <Button onClick={handleDownloadMaps} loading={downloadLoading}>
              Download maps
            </Button>
          )}
        </Box>
      </Modal>
    ),
  };
}
