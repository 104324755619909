import { useCallback, useState } from "react";
import { Button } from "../../../../components/button/button";
import { API } from "../../../../services/api";
import { MappingEvent } from "../../../../services/modules/mappingEvent/mappingEvent";
import { useMe } from "../../../../services/modules/user/useMe";
import { Participations } from "../../components/participations/participations";

interface RegistrationsOpenedProps {
  mappingEvent: MappingEvent;
}

export function RegistrationsOpened({
  mappingEvent,
}: RegistrationsOpenedProps) {
  const { user } = useMe();
  const [loading, setLoading] = useState(false);

  const isParticipating =
    user && mappingEvent.participations.some((e) => e.id === user?.id);

  const handleRegister = useCallback(async () => {
    setLoading(true);
    try {
      if (isParticipating) {
        await API.unparticipateFromMappingEvent(mappingEvent.id);
      } else {
        await API.participateToMappingEvent(mappingEvent.id);
      }
    } catch (e) {}
    setLoading(false);
  }, [mappingEvent.id, isParticipating]);

  return (
    <Participations
      participations={mappingEvent.participations}
      shouldWarnNotUploaded={false}
      rightParticpantsButton={
        <Button loading={loading} onClick={handleRegister}>
          {!isParticipating ? "Register for mapping" : "Unregister for mapping"}
        </Button>
      }
      forceParticipantDisplay
    />
  );
}
