export interface User {
  id: string;
  name: string;
  profilePictureUrl: string;
  tier: number;
  admin: boolean;
}

export function tierToChanceMultiplier(tier: number) {
  if (tier === 0) {
    return 1;
  }
  if (tier === 1) {
    return 5;
  }
  if (tier === 2) {
    return 10;
  }
  if (tier === 3) {
    return 15;
  }
  return 1;
}
