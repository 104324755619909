import { Link, useNavigate } from "react-router-dom";
import { API_ENDPOINT } from "../../services/api";
import { DateFormatter } from "../../services/date";
import { useListMappingEvent } from "../../services/modules/mappingEvent/useMappingEvent";
import { useMe } from "../../services/modules/user/useMe";
import { URLs } from "../../services/url";
import { Box } from "../box/box";
import { Selector } from "../selector/selector";
import { Text } from "../text/text";
import s from "./index.module.css";

export function Header() {
  const navigate = useNavigate();

  const { user } = useMe();
  const lightMappingEvents = useListMappingEvent();

  return (
    <Box
      bg="background"
      row
      ph="S32"
      pv="S24"
      h={100}
      items="center"
      content="space-between"
      grow
      shadow="big"
    >
      <Link to={URLs.home} className={s.logo}>
        <img src="/logo.png" alt="logo" />
      </Link>
      <Box row items="center" gap="S16">
        <Selector
          title="Previous editions"
          options={lightMappingEvents?.map((mappingEvent, index) => ({
            title: `#${index + 1} ${mappingEvent.theme}`,
            subtitle: DateFormatter.formatDate(mappingEvent.createdAt),
            onClick: () =>
              navigate(URLs.mappingEvent.replace(":id", mappingEvent.id)),
          }))}
        />
        {user ? (
          <Box row>
            <Text>Logged as {user.name}</Text>
            <a href={`${API_ENDPOINT}/logout`} className={s.logout}>
              Logout
            </a>
          </Box>
        ) : (
          <a className={s.login} href={`${API_ENDPOINT}/login`}>
            <img src="/twitch.png" height={24} alt="login with twitch" />
            <Text size="default" color="text">
              Log in
            </Text>
          </a>
        )}
      </Box>
    </Box>
  );
}
