// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";

// biome-ignore lint/style/noNonNullAssertion: this should exist
const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG!);

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);

if (process.env.REACT_APP_USE_EMULATOR) {
  connectFirestoreEmulator(firestore, "127.0.0.1", 8081);
}

export { firestore };
