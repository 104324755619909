import { useNavigate } from "react-router-dom";
import { Box, ButtonBox } from "../../components/box/box";
import { Text } from "../../components/text/text";
import { useListMappingEvent } from "../../services/modules/mappingEvent/useMappingEvent";
import { useMe } from "../../services/modules/user/useMe";
import { URLs } from "../../services/url";
import { useCreateMappingEvent } from "./components/createMappingEvent/useCreateMappingEvent";
import s from "./index.module.css";

export function Home() {
  const mappingEvents = useListMappingEvent();
  const { user } = useMe();

  const lastMappingEvent = mappingEvents.at(-1);
  const isLive = lastMappingEvent && lastMappingEvent.step !== "finished";

  const navigate = useNavigate();
  const { element, open } = useCreateMappingEvent();

  const cta = isLive ? (
    <ButtonBox
      onClick={() =>
        navigate(URLs.mappingEvent.replace(":id", lastMappingEvent.id))
      }
      borderColor="five"
      mt="S32"
      glow
    >
      <Box ph="S24" pv="S8">
        <Text size="title">JOIN LIVE EVENT</Text>
      </Box>
    </ButtonBox>
  ) : user?.admin ? (
    <ButtonBox onClick={open} borderColor="five" mt="S32" glow>
      <Box ph="S24" pv="S8">
        <Text size="title">CREATE EVENT</Text>
      </Box>
    </ButtonBox>
  ) : undefined;

  return (
    <>
      <Box mt="S32" items="center" minh="100vh">
        <Text
          color="textYellowMat"
          size="main"
          weight="extrabold"
          className={s.title}
        >
          SATURDAY MAPPING FEST
        </Text>
        <Text size="title" italic>
          <Box row items="center" gap="S8">
            hosted by
            <img src="/brena.png" height={32} alt="bren" />
            Bren, every saturday at <strong>2:30PM CEST</strong>
          </Box>
        </Text>
        {cta}
        <Box
          r="big"
          b="five"
          bw={1}
          bg="backgroundDark"
          p="S32"
          w="60%"
          mt="S32"
        >
          <Box items="center" mb="S24">
            <Text size="big" weight="bold" color="textYellowFaded">
              How it works
            </Text>
          </Box>
          <Box row gap="S32">
            <Box flex={1} gap="S8">
              <Text size="title" weight="bold" color="textYellowFaded">
                REGISTRATION
              </Text>
              <Text>
                Registration is ONLY for mappers!! Log on to the website using
                your Twitch account. 14 mappers get randomly selected.
                Subscribers have increased chances based on their subscription
                tier.
              </Text>
            </Box>
            <Box flex={1} gap="S8">
              <Text size="title" weight="bold" color="textYellowFaded">
                MAPPING
              </Text>
              <Text>
                Every week, a new theme for mapping is announced. Selected
                mappers have 30 minutes to create a map based on the theme. Maps
                are uploaded through the event website.
              </Text>
            </Box>
          </Box>
          <Box row gap="S32" mt="S32">
            <Box flex={1} gap="S8">
              <Text size="title" weight="bold" color="textYellowFaded">
                PLAYING
              </Text>
              <Text>
                All submitted maps are uploaded to a server where everyone can
                join without registering. A Time Attack session is held on the
                newly created maps. Players vote on each map, resulting in a
                Mappers Ranking.
              </Text>
            </Box>
            <Box flex={1} gap="S8">
              <Text size="title" weight="bold" color="textYellowFaded">
                RANKING
              </Text>
              <Text>
                Mappers ranking is determined by player votes (--, -, +-, +,
                ++). Drivers ranking is based on the cumulative personal best
                across all maps.
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      {element}
    </>
  );
}
