import clsx from "clsx";
import { CSSProperties, ReactNode } from "react";
import { rawColor, templateShadow } from "../../services/constants";
import { Loader } from "../loader/loader";
import s from "./index.module.css";

export interface ButtonProps {
  children: ReactNode;
  onClick: () => void;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  icon?: ReactNode;
  borderColor?: keyof typeof rawColor;
  style?: CSSProperties;
  glow?: boolean;
}

export function Button({
  children,
  onClick,
  icon,
  loading,
  className,
  disabled,
  borderColor = "white",
  style,
  glow,
}: ButtonProps) {
  const interactable = !loading && !disabled;

  return (
    <button
      className={clsx(s.root, !interactable && s.disabled, className)}
      style={{
        ...style,
        boxShadow: glow ? templateShadow("small", borderColor) : undefined,
        borderColor: borderColor ? rawColor[borderColor] : undefined,
        backgroundColor: rawColor.two,
      }}
      type="button"
      disabled={!interactable}
      onClick={onClick}
    >
      {icon}
      {children}
      {loading && <Loader />}
    </button>
  );
}
