import { collection, doc } from "firebase/firestore";
import { useMemo } from "react";
import { firestore } from "../../firestore.provider";
import { useFirestoreQuery } from "../../hooks";
import {
  ListMappingEvent,
  MappingEvent,
  TimeScoreboardMappingEvent,
  VoteScoreboardMappingEvent,
} from "./mappingEvent";

export function useListMappingEvent() {
  const result = useFirestoreQuery<ListMappingEvent>(
    "list-mapping-events",
    doc(collection(firestore, "list-mapping-events"), "global"),
  );

  return useMemo(
    () =>
      result?.mappingEvents.sort(
        (a, b) => a.createdAt.getTime() - b.createdAt.getTime(),
      ) ?? [],
    [result],
  );
}

export function useMappingEvent(id: string | undefined) {
  const result = useFirestoreQuery<MappingEvent>(
    `mapping-event-${id}`,
    doc(collection(firestore, "front-mapping-events"), id),
  );

  return result;
}

export function useTimeScoreboardMappingEvent(id: string | undefined) {
  const result = useFirestoreQuery<TimeScoreboardMappingEvent>(
    `time-scoreboard-mapping-event-${id}`,
    doc(collection(firestore, "time-scoreboard-mapping-events"), id),
  );

  return result;
}

export function useVoteScoreboardMappingEvent(id: string | undefined) {
  const result = useFirestoreQuery<VoteScoreboardMappingEvent>(
    `vote-scoreboard-mapping-event-${id}`,
    doc(collection(firestore, "vote-scoreboard-mapping-events"), id),
  );

  return result;
}
