import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Box } from "./components/box/box";
import { Header } from "./components/header/header";
import { Home } from "./screens/home/home";
import { Login } from "./screens/login/login";
import { MappingEvent } from "./screens/mappingEvent/mappingEvent";
import { injectCssVariables } from "./services/constants";
import { URLs } from "./services/url";

injectCssVariables();

export function App() {
  return (
    <Box className="App" bg="background">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path={URLs.mappingEvent} Component={MappingEvent} />
          <Route path={URLs.home} Component={Home} />
          <Route path={URLs.login} Component={Login} />
        </Routes>
      </BrowserRouter>
    </Box>
  );
}
