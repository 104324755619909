import { Box } from "../../../../components/box/box";
import { useTimeScoreboardMappingEvent } from "../../../../services/modules/mappingEvent/useMappingEvent";
import { ScoreboardLine } from "./timeScoreboardLine/timeScoreboadLine";

interface TimeScoreboardProps {
  mappingEventId: string;
}

export function TimeScoreboard({ mappingEventId }: TimeScoreboardProps) {
  const scoreboard = useTimeScoreboardMappingEvent(mappingEventId);

  const names = scoreboard?.names ?? {};

  return (
    <Box gap="S8">
      {scoreboard?.total.map((score, index) => (
        <ScoreboardLine
          key={score.ubisoftId}
          rank={index + 1}
          name={names[score.ubisoftId] ?? "not found"}
          time={score.time}
        />
      ))}
    </Box>
  );
}
