import { useCallback, useState } from "react";
import { Button } from "../../../../components/button/button";
import { Input } from "../../../../components/input/input";
import { Modal } from "../../../../components/modal/modal";
import { API } from "../../../../services/api";
import { useBooleanState } from "../../../../services/hooks";
import s from "./index.module.css";

export function useCreateMappingEvent() {
  const [isOpen, open, close] = useBooleanState();

  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useState("");

  const handleCreate = useCallback(async () => {
    setLoading(true);
    try {
      await API.createMappingEvent(theme);
    } catch (e) {}
    setLoading(false);
    close();
  }, [theme, close]);

  return {
    isOpen,
    open,
    close,
    element: (
      <Modal open={isOpen} onClose={close} title="Create a mapping event">
        <Input placeholder="Theme" value={theme} onChangeText={setTheme} />
        <Button onClick={handleCreate} className={s.button} loading={loading}>
          Create
        </Button>
      </Modal>
    ),
  };
}
