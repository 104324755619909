export const DateFormatter = {
  formatDate(date: Date) {
    return new Intl.DateTimeFormat(undefined, {
      month: "long",
      year: "numeric",
      day: "numeric",
    }).format(date);
  },
  msToDuration(ms: number) {
    let decreasingMs = ms;
    const minutes = Math.floor(decreasingMs / (1000 * 60));
    decreasingMs -= minutes * (1000 * 60);
    const seconds = Math.floor(decreasingMs / 1000);
    decreasingMs -= seconds * 1000;
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}.${decreasingMs.toString().padStart(3, "0")}`;
  },
};

export const DateUtils = {
  sameDay(a: Date, b: Date) {
    return (
      a.getFullYear() === b.getFullYear() &&
      a.getMonth() === b.getMonth() &&
      a.getDate() === b.getDate()
    );
  },
};
