import React, { CSSProperties, ReactNode, useMemo, HTMLProps } from "react";

import {
  AutoSpacing,
  Spacing,
  color,
  debugBorder,
  getSpacing,
  opacify,
  radius,
  rawColor,
  spacing,
  shadow as uishadow,
} from "../../services/constants";
import { Button, ButtonProps } from "../button/button";

export interface BoxProps {
  m?: AutoSpacing;
  mh?: AutoSpacing;
  mv?: AutoSpacing;
  mt?: AutoSpacing;
  mb?: AutoSpacing;
  ml?: AutoSpacing;
  mr?: AutoSpacing;
  p?: AutoSpacing;
  ph?: AutoSpacing;
  pv?: AutoSpacing;
  pt?: AutoSpacing;
  pb?: AutoSpacing;
  pl?: AutoSpacing;
  pr?: AutoSpacing;
  gap?: Spacing;
  maxh?: CSSProperties["maxHeight"];
  maxw?: CSSProperties["maxWidth"];
  minw?: CSSProperties["minWidth"];
  minh?: CSSProperties["minHeight"];
  basis?: CSSProperties["flexBasis"];
  overflow?: CSSProperties["overflow"];
  h?: CSSProperties["height"] | CSSProperties["height"];
  w?: CSSProperties["width"] | CSSProperties["width"];
  r?: keyof typeof radius;
  shrink?: boolean;
  grow?: boolean;
  items?: CSSProperties["alignItems"];
  content?: CSSProperties["justifyContent"];
  bg?: keyof typeof color | [keyof typeof color, number];
  row?: boolean;
  debug?: boolean;
  children?: ReactNode;
  flex?: number;
  opacity?: number;
  ratio?: CSSProperties["aspectRatio"];
  shadow?: keyof typeof uishadow;
  className?: string;
  b?: keyof typeof rawColor;
  bw?: number;
  style?: CSSProperties;
}

function getComputedColor(
  c: keyof typeof color | [keyof typeof color, number],
) {
  if (Array.isArray(c)) {
    return opacify(...c);
  }
  return color[c];
}

function useBoxStyle({
  mb,
  ml,
  mr,
  mt,
  pb,
  pl,
  pr,
  pt,
  p,
  m,
  mh,
  mv,
  ph,
  pv,
  h,
  w,
  r,
  basis,
  overflow,
  row,
  gap,
  maxh,
  maxw,
  items,
  content,
  shrink,
  grow,
  bg,
  debug,
  flex,
  opacity,
  ratio,
  minw,
  shadow,
  minh,
  b,
  bw,
  style,
}: BoxProps) {
  return useMemo<CSSProperties>(() => {
    const obj: CSSProperties = {};
    obj.display = "flex";
    if (m) obj.margin = getSpacing(m);
    if (mt || mv) obj.marginTop = getSpacing(mt) ?? getSpacing(mv);
    if (mb || mv) obj.marginBottom = getSpacing(mb) ?? getSpacing(mv);
    if (ml || mh) obj.marginLeft = getSpacing(ml) ?? getSpacing(mh);
    if (mr || mh) obj.marginRight = getSpacing(mr) ?? getSpacing(mh);
    if (p) obj.padding = getSpacing(p);
    if (pt || pv) obj.paddingTop = getSpacing(pt) ?? getSpacing(pv);
    if (pb || pv) obj.paddingBottom = getSpacing(pb) ?? getSpacing(pv);
    if (pl || ph) obj.paddingLeft = getSpacing(pl) ?? getSpacing(ph);
    if (pr || ph) obj.paddingRight = getSpacing(pr) ?? getSpacing(ph);
    obj.flexDirection = row ? "row" : "column";
    if (gap) obj.gap = spacing[gap];
    if (items) obj.alignItems = items;
    if (content) obj.justifyContent = content;
    if (shrink !== undefined) obj.flexShrink = shrink ? 1 : 0;
    if (grow !== undefined) obj.flexGrow = grow ? 1 : 0;
    if (w !== undefined) obj.width = w as any;
    if (h !== undefined) obj.height = h as any;
    if (bg) obj.backgroundColor = getComputedColor(bg);
    if (maxw !== undefined) obj.maxWidth = maxw;
    if (minw !== undefined) obj.minWidth = minw;
    if (minh !== undefined) obj.minHeight = minh;
    if (maxh !== undefined) obj.maxHeight = maxh;
    if (r) obj.borderRadius = radius[r];
    if (overflow) obj.overflow = overflow;
    if (basis !== undefined) obj.flexBasis = basis;
    if (flex !== undefined) obj.flex = flex;
    if (opacify !== undefined) obj.opacity = opacity;
    if (ratio !== undefined) obj.aspectRatio = ratio;
    if (shadow) obj.boxShadow = uishadow[shadow];
    if (b) obj.borderColor = b ? rawColor[b] : undefined;
    if (bw !== undefined) obj.borderWidth = bw;
    if (b && bw) obj.borderStyle = "solid";
    Object.assign(obj, debug ? debugBorder("red") : {});
    Object.assign(obj, style ? style : {});
    return obj;
  }, [
    m,
    mv,
    mh,
    mt,
    mb,
    ml,
    mr,
    p,
    pv,
    ph,
    pt,
    pb,
    pl,
    pr,
    row,
    gap,
    items,
    content,
    shrink,
    grow,
    w,
    h,
    bg,
    maxw,
    minw,
    maxh,
    r,
    overflow,
    basis,
    flex,
    opacity,
    ratio,
    debug,
    shadow,
    minh,
    b,
    bw,
    style,
  ]);
}

function withBox<Props>(Component: any) {
  return ({
    basis,
    bg,
    content,
    debug,
    flex,
    gap,
    grow,
    h,
    items,
    m,
    maxh,
    maxw,
    mb,
    mh,
    ml,
    mr,
    mt,
    mv,
    opacity,
    overflow,
    p,
    pb,
    ph,
    pl,
    pr,
    pt,
    pv,
    r,
    row,
    shrink,
    w,
    ratio,
    minw,
    className,
    children,
    shadow,
    minh,
    b,
    bw,
    style,
    ...props
  }: Props & BoxProps) => {
    const styles = useBoxStyle({
      basis,
      bg,
      content,
      debug,
      flex,
      gap,
      grow,
      h,
      items,
      m,
      maxh,
      maxw,
      mb,
      mh,
      ml,
      mr,
      mt,
      mv,
      opacity,
      overflow,
      p,
      pb,
      ph,
      pl,
      pr,
      pt,
      pv,
      r,
      row,
      shrink,
      w,
      ratio,
      minw,
      shadow,
      minh,
      b,
      bw,
      style,
    });
    return React.createElement(Component, {
      children,
      ...props,
      style: styles,
      className,
    } as any);
  };
}

export const Box = withBox<HTMLProps<HTMLDivElement>>("div");
export const ButtonBox = withBox<ButtonProps>(Button);
