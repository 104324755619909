import { Typography } from "@mui/material";
import { ReactNode } from "react";
import s from "./index.module.css";

interface InfoIconProps {
  left: ReactNode;
  right: ReactNode;
}

export function InfoIcon({ left, right }: InfoIconProps) {
  return (
    <div className={s.line}>
      <Typography className={s.linestart} variant="body1" color="whitesmoke">
        {left}
      </Typography>
      <Typography variant="body1" color="whitesmoke">
        {right}
      </Typography>
    </div>
  );
}
