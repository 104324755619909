import { EmojiEvents } from "@mui/icons-material";
import { Box } from "../../../../../components/box/box";
import { Text } from "../../../../../components/text/text";
import { color } from "../../../../../services/constants";
import { DateFormatter } from "../../../../../services/date";
import s from "./index.module.css";

interface ScoreboardLineProps {
  rank: number;
  name: string;
  time: number;
}

export function ScoreboardLine({ rank, name, time }: ScoreboardLineProps) {
  return (
    <Box
      p="S8"
      row
      items="center"
      content="space-between"
      bg="backgroundDark"
      r="round"
    >
      <Box row gap="S16">
        {rank === 1 ? (
          <EmojiEvents style={{ color: color.textYellowMat }} />
        ) : (
          <Text weight="bold">#{rank}</Text>
        )}
        <Text>{name}</Text>
      </Box>
      <Text className={s.time}>{DateFormatter.msToDuration(time)}</Text>
    </Box>
  );
}
