import { useCallback, useState } from "react";
import { Loader } from "../../../../../components/loader/loader";
import { Modal } from "../../../../../components/modal/modal";
import { Text } from "../../../../../components/text/text";
import { API, getStringFromError } from "../../../../../services/api";
import { useBooleanState } from "../../../../../services/hooks";
import s from "./index.module.css";

interface UseUploadMapPopupProps {
  mappingEventId: string;
}

export function useUploadMapPopup({ mappingEventId }: UseUploadMapPopupProps) {
  const [key, setKey] = useState(false);
  const [isOpen, open, close] = useBooleanState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const handleClose = useCallback(() => {
    close();
    setError(undefined);
  }, [close]);

  const handleMapUpload = useCallback(
    async (file: File | undefined) => {
      if (!file) {
        return;
      }
      setLoading(true);
      try {
        await API.uploadMappingEventMap(mappingEventId, file);
        handleClose();
      } catch (e) {
        setError(getStringFromError(e));
        setKey((o) => !o);
      }
      setLoading(false);
    },
    [mappingEventId, handleClose],
  );

  return {
    isOpen,
    open,
    close,
    element: (
      <Modal open={isOpen} onClose={handleClose} title="Upload your map">
        <Text>
          Ready to upload your map?
          <br />
          Just make sure of a few things before uploading:
        </Text>
        <ul>
          <li className={s.item}>
            <Text>The map is validated</Text>
          </li>
          <li className={s.item}>
            <Text>The shadows are calculated</Text>
          </li>
        </ul>
        {error ? <Text color="error">{error}</Text> : null}
        <label className={s.wrapper} key={key.toString()}>
          <input
            type="file"
            className={s.input}
            hidden
            multiple={false}
            onChange={(ev) => handleMapUpload(ev.target.files?.[0])}
          />
          <span className={s.replacedinput}>
            {loading ? <Loader /> : "I upload my map"}
          </span>
        </label>
      </Modal>
    ),
  };
}
