import ReactDOM from "react-dom/client";
import "./index.css";
import { ThemeProvider, createTheme } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
import { App } from "./App";
import { color } from "./services/constants";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const theme = createTheme({
  palette: {
    primary: {
      main: color.textYellowMat,
    },
    text: {
      primary: "white",
      secondary: "white",
    },
  },
});

function QueryClientWrapper() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

root.render(<QueryClientWrapper />);
