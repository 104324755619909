import { DocumentReference, Query, onSnapshot } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { decodeFirebaseTimestamps } from "./firestore.utils";
import { Listeners } from "./listeners";

export function useRender() {
  const [_, set] = useState(false);

  return useCallback(() => set((o) => !o), []);
}

export function useBooleanState() {
  const [open, setOpen] = useState(false);

  const setters = useMemo(
    () => [() => setOpen(true), () => setOpen(false)] as const,
    [],
  );

  return [open, ...setters] as const;
}

export function usePrevious<T>(previous: T) {
  const previousRef = useRef(previous);
  const toReturn = previousRef.current;
  previousRef.current = previous;
  return toReturn;
}

const subscriptions: Record<
  string,
  { listeners: Listeners<any>; unsubscribe: () => void; lastResult: any }
> = {};

export function useFirestoreQuery<T>(
  key: string,
  query: Query | DocumentReference | undefined,
  keepPrevious = true,
) {
  const render = useRender();
  const refquery = useRef(query);
  refquery.current = query;
  const typedKey = `${refquery.current?.type ?? "undefined"}-${key}`;

  useEffect(() => {
    if (!refquery.current) {
      return;
    }
    const existing = subscriptions[typedKey];
    if (existing) {
      existing.listeners.register(render);
    } else {
      const listeners = new Listeners<any>();
      listeners.register(render);
      let unsubscribe: (() => void) | undefined;

      if (refquery.current.type === "document") {
        unsubscribe = onSnapshot(refquery.current, {
          next(snapshot) {
            const sub = subscriptions[typedKey];
            if (sub) {
              const data = snapshot.data();
              sub.lastResult = data
                ? decodeFirebaseTimestamps(data)
                : undefined;
              listeners.trigger();
            }
          },
        });
      } else if (
        refquery.current.type === "query" ||
        refquery.current.type === "collection"
      ) {
        unsubscribe = onSnapshot(refquery.current, {
          next(snapshot) {
            const sub = subscriptions[typedKey];
            if (sub) {
              sub.lastResult = snapshot.docs.map((doc) =>
                decodeFirebaseTimestamps(doc.data()),
              );
              listeners.trigger();
            }
          },
        });
      } else {
        throw new Error("Unsupported query");
      }

      subscriptions[typedKey] = {
        listeners,
        unsubscribe,
        lastResult: undefined,
      };
    }
    return () => {
      const ex = subscriptions[typedKey];
      if (!ex) {
        return;
      }
      ex.listeners.unregister(render);
      if (ex.listeners.count === 0) {
        ex.unsubscribe();
        delete subscriptions[typedKey];
      }
    };
  }, [typedKey, render]);

  if (keepPrevious) {
    const last = usePrevious(
      subscriptions[typedKey]?.lastResult as T | undefined,
    );
    return (subscriptions[typedKey]?.lastResult ?? last) as T | undefined;
  }
  return subscriptions[typedKey]?.lastResult as T | undefined;
}
