import clsx from "clsx";
import s from "./index.module.css";

interface InputProps {
  placeholder?: string;
  value: string;
  onChangeText: (text: string) => void;
  className?: string;
}

export function Input({
  onChangeText,
  value,
  placeholder,
  className,
}: InputProps) {
  return (
    <input
      className={clsx(s.root, className)}
      placeholder={placeholder}
      value={value}
      onChange={(ev) => onChangeText(ev.target.value)}
    />
  );
}
