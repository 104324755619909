import { doc } from "firebase/firestore";
import { useQuery } from "react-query";
import { API } from "../../api";
import { firestore } from "../../firestore.provider";
import { useFirestoreQuery } from "../../hooks";
import { User } from "./user";

export function useMe() {
  const { data, isLoading } = useQuery(["api", "me"], {
    queryFn: () => API.me(),
    retry: false,
  });

  const user = useFirestoreQuery<User>(
    data?.id ?? "unknown",
    data ? doc(firestore, "users", data.id) : undefined,
    false,
  );

  return {
    user: user as User | undefined,
    apiUser: data,
    isLoading,
  };
}
