import s from "./index.module.css";

export function PulsatingDot() {
  return (
    <div className={s.root}>
      <div className={s.ring} />
      <div className={s.circle} />
    </div>
  );
}
