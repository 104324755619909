import { MappingEvent } from "../../../../services/modules/mappingEvent/mappingEvent";
import { Participations } from "../../components/participations/participations";

interface CompetitionStartedProps {
  mappingEvent: MappingEvent;
}

export function CompetitionStarted({ mappingEvent }: CompetitionStartedProps) {
  return (
    <Participations
      participations={mappingEvent.participations}
      shouldWarnNotUploaded={false}
      forceParticipantDisplay={false}
    />
  );
}
