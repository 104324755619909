export class Listeners<T extends (...args: any[]) => void> {
  private listeners: T[] = [];

  get count() {
    return this.listeners.length;
  }

  register(callback: T) {
    this.listeners.push(callback);
  }

  unregister(callback: T) {
    const index = this.listeners.indexOf(callback);
    if (index < 0) {
      return;
    }
    this.listeners.splice(index, 1);
  }

  trigger(...args: Parameters<T>) {
    for (const listener of this.listeners) {
      listener(...args);
    }
  }
}
