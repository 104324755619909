import { CSSProperties, ReactNode } from "react";
import { fontSize, color as uicolor } from "../../services/constants";

const weights = {
  regular: "regular",
  bold: "bold",
  extrabold: "900",
} as const;

type Weight = keyof typeof weights;

interface TextProps {
  size?: keyof typeof fontSize;
  children: ReactNode;
  color?: keyof typeof uicolor;
  weight?: Weight;
  italic?: boolean;
  className?: string;
  align?: CSSProperties["textAlign"];
}

export function Text({
  size = "default",
  color = "text",
  weight = "regular",
  italic,
  children,
  className,
  align,
}: TextProps) {
  return (
    <span
      className={className}
      style={{
        fontFamily: "Montserrat",
        fontSize: fontSize[size],
        color: uicolor[color],
        fontWeight: weights[weight],
        fontStyle: italic ? "italic" : undefined,
        textAlign: align,
      }}
    >
      {children}
    </span>
  );
}
