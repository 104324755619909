import { Box } from "../../../../components/box/box";
import { Text } from "../../../../components/text/text";

export function Initial() {
  return (
    <Box gap="S8" items="center" content="center" m="auto" w={500}>
      <img
        src="https://cdn.7tv.app/emote/6253a913c2be2d716f88a7f1/4x.webp"
        alt="waiting"
        width={64}
      />
      <Text color="text" size="default" align="center">
        Event has not started yet, you should go watch Bren's stream to know
        when it will start.
      </Text>
    </Box>
  );
}
