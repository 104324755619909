import { Tooltip, Typography } from "@mui/material";
import { Box } from "../../../../../components/box/box";
import { Text } from "../../../../../components/text/text";
import { tierToChanceMultiplier } from "../../user";
import s from "./index.module.css";

interface UserLineProps {
  name: string;
  profilePictureUrl: string;
  atTier: number;
  uploaded: boolean;
  shouldWarnNotUploaded: boolean;
}

export function UserLine({
  name,
  profilePictureUrl,
  atTier,
  uploaded,
  shouldWarnNotUploaded,
}: UserLineProps) {
  return (
    <div className={s.root}>
      <div className={s.left}>
        <img
          src={profilePictureUrl}
          height={48}
          width={48}
          alt={`${name} profile`}
          className={s.pp}
        />
        <Typography variant="body1" color="whitesmoke">
          {name}
        </Typography>
        <Box ml="S16">
          {!uploaded && shouldWarnNotUploaded ? (
            <Text weight="bold" color="warning" size="smaller">
              Not uploaded
            </Text>
          ) : null}
          {uploaded && shouldWarnNotUploaded ? (
            <Text weight="bold" color="success" size="smaller">
              Uploaded
            </Text>
          ) : null}
        </Box>
      </div>
      <div>
        {atTier > 0 ? (
          <Tooltip
            title={
              <div className={s.tooltip}>
                This user is subscribed <strong>Tier {atTier}</strong> to Bren's
                channel, and is{" "}
                <strong>{tierToChanceMultiplier(atTier)}</strong> times likely
                to be selected.
              </div>
            }
          >
            <div className={s.tier}>
              <svg
                width="100%"
                height="100%"
                version="1.1"
                viewBox="0 0 20 20"
                x="0px"
                y="0px"
                data-a-selector="tw-core-button-icon"
                aria-hidden="true"
                className={s.star}
                fill="#9147ff"
              >
                <g>
                  <path d="M8.944 2.654c.406-.872 1.706-.872 2.112 0l1.754 3.77 4.2.583c.932.13 1.318 1.209.664 1.853l-3.128 3.083.755 4.272c.163.92-.876 1.603-1.722 1.132L10 15.354l-3.579 1.993c-.846.47-1.885-.212-1.722-1.132l.755-4.272L2.326 8.86c-.654-.644-.268-1.723.664-1.853l4.2-.583 1.754-3.77z" />
                </g>
              </svg>
              <Typography
                variant="body2"
                color="whitesmoke"
                flexShrink={0}
                marginTop={0.2}
                marginLeft={0.5}
              >
                Tier {atTier}
              </Typography>
            </div>
          </Tooltip>
        ) : (
          <Typography variant="body2" color="GrayText">
            Not subscribed
          </Typography>
        )}
      </div>
    </div>
  );
}
